import { isNil } from 'lodash';

const mutations = {
	setAceiteTermosFiltro: (state, aceito) => {
		state.termosFiltroAceito = aceito;
	},

	setVinculoSelecionado: (state, vinculo) => {
		state.vinculoSelecionado = vinculo;
	},

	loading: (state, loading) => {
		state.isLoading = loading;
	},

	setVinculos: (state, cursos) => {
		const vinculosCategorizados = {};

		if (isNil(cursos) || cursos.length <= 0) {
			state.vinculosFiltro = [];
			return;
		}

		if (cursos.length === 1) {
			state.vinculoSelecionado = cursos[0];
		}

		cursos = cursos.sort((a, b) => {
			a.status_descricao > b.status_descricao
		});

		cursos.forEach(curso => {
			if (!Object.prototype.hasOwnProperty.call(vinculosCategorizados, curso.status_descricao)) {
				vinculosCategorizados[curso.status_descricao] = [];
			}

			vinculosCategorizados[curso.status_descricao].push(curso);
		});

		state.vinculosFiltro = vinculosCategorizados;

		let temAtivos = typeof(vinculosCategorizados) === 'object' && 'Ativo' in vinculosCategorizados && vinculosCategorizados.Ativo.length > 0;

		if (!temAtivos) {
			state.permiteFiltrarTodosAtivos = false;
		}

		if (state.vinculoSelecionado == '') {
			if (temAtivos) {
				state.vinculoSelecionado = 'Todos os Vínculos Ativos';
			} else {
				state.vinculoSelecionado = 'Todos os Vínculos';
			}
		}		
	},

	setPermitirFiltrarTodos: (state, permitir) => {
		state.permiteFiltrarTodos = permitir;
	}

};

export default mutations;
