const getters = {
	isLoading(state) {
		return state.loading;
	},

	isLoaded(state) {
		return state.loaded;
	},

	foto(state) {
		if (state.foto != '' && state.foto != null) {
			if (state.foto.match(/^https?:\/\//)) {
				return state.foto;
			} else {
				return window.location.protocol + '//' + window.location.hostname + state.foto;
			}
		}

		return 'person_no_picture.svg';
	},

	getEmail: (state) => {
		return state.email;
	},

	getCelular: (state) => {
		return state.celular;
	}
};

export default getters;
