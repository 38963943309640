<template>
	<div />
</template>

<script>
import Color from 'colorjs.io';

export default {
	name: 'Theme',
	beforeCreate(){
		let style = document.createElement('style');
		style.id = 'theme-color';

		let { primary } = this.$store.state.ie.style.colors;

		const primaryTransparent = new Color(primary);

		primaryTransparent.alpha = 0.25;

		style.innerHTML = `
            :root{
                --primary: ${primary};
								--primary-outline: ${ primaryTransparent.toString() };
                --primary-hover: ${new Color(primary).darken(0.3).toString()};
                --light-green: ${new Color(primary).lighten(0.2).toString()};
            }
        `;
		document.getElementsByTagName('head')[0].appendChild(style);
	}
}
</script>
