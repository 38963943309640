const mutations = {
	setNovasExperiencias: (state, novasExperiencias) => {
		state.novasExperiencias = novasExperiencias;
	},

	setMinhasExperiencias: (state, minhasExperiencias) => {
		state.minhasExperiencias = minhasExperiencias;
	},

	setEncontrosComEspecialista: (state, encontrosComEspecialista) => {
		state.encontrosComEspecialista = encontrosComEspecialista;
	},

	loading: (state, loading) => {
		state.isLoading = loading;
	},

	setInteresseAlto(state, { id, confirmado = false }) {
		state.interesseAlto.id = id;

		state.interesseAlto.confirmado = confirmado;
	},

	setInteresseMuitoAlto(state, { id, confirmado = false }) {
		state.interesseMuitoAlto.id = id;

		state.interesseMuitoAlto.confirmado = confirmado;
	},
	setVisualizouModalExperiencias(state, visualizou) {
		state.visualizouModalExperiencias = visualizou;
	},
	setCodigoIes(state, codigo_ies) {
		state.codigo_ies = codigo_ies;
	},
};

export default mutations;
