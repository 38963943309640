import http from '@commons/http';
import moment from 'moment';
import { isNil, isObjectLike } from 'lodash';
import {
	InteresseValorEnum,
	InteresseStatusEnum,
} from '@enum/experiencias/interesse';
import { VinculoStatusEnum } from '@enum/experiencias/vinculo';
import { ExperienciaTipoEnum } from '@enum/experiencias/experiencia';

const InteresseActionMap = Object.freeze({
	[InteresseValorEnum.MEDIO]: null,
	[InteresseValorEnum.ALTO]: 'setInteresseAlto',
	[InteresseValorEnum.MUITO_ALTO]: 'setInteresseMuitoAlto',
})

const actions = {
	todasExperiencias: async ({ commit, dispatch, rootGetters }) => {

		try {
			if (rootGetters['vinculosAcademicos/isVinculoTodos']) {
				dispatch('limpar');

				return;
			}

			commit('loading', true);

			const vinculo = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

			let filtro = null;

			if (!isNil(vinculo) && isObjectLike(vinculo)) {
				filtro = {
					nens_id: vinculo.nens_id,
					curs_id: vinculo.curs_id,
					habi_id: vinculo.habi_id,
					turn_id: vinculo.turn_id,
					viac_sequencia: vinculo.viac_sequencia,
					peri_id: vinculo.periodo,
				};
			}

			const response = await http.get('/v1/experiencia/aluno', filtro);

			if (response.status === 200) {
				const data = response.data;

				const novasExperiencias = data.disponiveis || [];

				const vinculos_interesses = data.vinculos_interesses || [];

				const minhasExperiencias = [];
				const encontrosComEspecialista = [];

				const agora = moment();

				if (!isNil(vinculos_interesses.vinculos)) {
					for (const [key, vinculo] of Object.entries(vinculos_interesses.vinculos)) {
						const temp = vinculo.experiencia;

						temp.tipo = ExperienciaTipoEnum.VINCULO;

						temp.vinculo = {
							id: parseInt(key, 10),
							status: vinculo.status,
							id_ulife: vinculo.id_ulife,
						};

						if (
							temp.vinculo.status === VinculoStatusEnum.CANCELADO
							|| temp.vinculo.status === VinculoStatusEnum.DESISTENTE
						) {
							continue;
						}

						if (temp.tipo_experiencia.nome === 'Encontro com Especialistas') {
							encontrosComEspecialista.push(temp);
						} else {
							minhasExperiencias.push(temp);
						}
					}
				}

				if (!isNil(vinculos_interesses.interesses)) {
					for (const [key, vinculo] of Object.entries(vinculos_interesses.interesses)) {
						const temp = vinculo.experiencia;

						temp.tipo = ExperienciaTipoEnum.INTERESSE;

						temp.interesse = {
							id: parseInt(key, 10),
							status: vinculo.status_interesse,
							prioridade: parseInt(vinculo.prioridade, 10),
						};

						if (temp.interesse.status === InteresseStatusEnum.AGUARDANDO) {
							const action = InteresseActionMap[temp.interesse.prioridade];

							if (action) {
								commit(
									action,
									{
										id: temp.id,
										confirmado: true,
									}
								);
							}
						}

						minhasExperiencias.push(temp);

						if (agora <= moment(temp.data_termino)) {
							novasExperiencias.push(temp);
						}
					}
				}

				commit('setCodigoIes', data.codigo_ies);
				commit('setNovasExperiencias', novasExperiencias);
				commit('setMinhasExperiencias', minhasExperiencias);
				commit('setEncontrosComEspecialista', encontrosComEspecialista);
			}
		} catch (error) {
			console.log(error);
		} finally {
			commit('loading', false);
		}
	},

	setVisualizouModalExperienciaAction: ({ commit }) => {
		http.post('/v1/aluno/visualizou-modal-experiencias', []);
		commit('setVisualizouModalExperiencias', true);
	},
	setInteresseAlto({ commit, getters }, id) {
		if (getters['getInteresseAlto'].confirmado) {
			return false;
		}

		commit('setInteresseAlto', { id });

		return true;
	},

	setInteresseMuitoAlto({ commit, getters }, id) {
		if (getters['getInteresseMuitoAlto'].confirmado) {
			return false;
		}

		commit('setInteresseMuitoAlto', { id });

		return true;
	},

	async concorrer({ commit, dispatch, getters, rootGetters }, { id, prioridade }) {
		const data = {
			experiencia: id,
			prioridade: prioridade,
			viac_id: rootGetters['vinculosAcademicos/getVinculoSelecionado'].viac_id,
		};

		try {
			dispatch('ui/loading', true, { root: true });

			const response = await http.post(
				'/v1/experiencia/manifestacao-interesse',
				new URLSearchParams(Object.entries(data)).toString()
			);

			if (response.data.status !== 'success') {
				throw new Error(response.data.message);
			}

			const action = InteresseActionMap[prioridade];

			if (action) {
				commit(
					action,
					{
						id,
						confirmado: true,
					}
				);
			}

			const experiencia = getters['getNovasExperiencias'].find(exp => exp.id === id);

			experiencia.tipo = ExperienciaTipoEnum.INTERESSE;

			experiencia.interesse = {
				id,
				status: InteresseStatusEnum.AGUARDANDO,
				prioridade,
			};

			const minhasExperiencias = getters['getMinhasExperiencias'];

			minhasExperiencias.push(experiencia);

			commit('setMinhasExperiencias', minhasExperiencias);
		} catch (error) {
			throw new Error(error?.data?.message || error.message);
		} finally {
			dispatch('ui/loading', false, { root: true });
		}
	},

	limpar({ commit }) {
		commit('setNovasExperiencias', []);
		commit('setMinhasExperiencias', []);
		commit('setEncontrosComEspecialista', []);
		commit(InteresseActionMap[InteresseValorEnum.ALTO], { id: null, confirmado: false });
		commit(InteresseActionMap[InteresseValorEnum.MUITO_ALTO], { id: null, confirmado: false });
	}
};

export default actions;
