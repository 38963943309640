import http from '@commons/http';

const statusDetalheMap = {
	'SOLICITADO': 'ACOMPANHAR',
	'EM ANALISE': 'ACOMPANHAR',
	'FINALIZADO': 'CONSULTAR',
	'PENDENTE MATRICULA': 'ACOMPANHAR',
	'AGUARDANDO PAGAMENTO': 'PAGAR',
	'CANCELADO': 'CONSULTAR',
};

const parseRequerimento = requerimento => {
	requerimento.status_detalhe = typeof statusDetalheMap[requerimento.reqs_descricao_web] !== 'undefined'
		? statusDetalheMap[requerimento.reqs_descricao_web]
		: statusDetalheMap['SOLICITADO'];

	return requerimento;
}

const actions = {
	carregaSecoes: async ({rootGetters, commit, dispatch}) =>  {
		try {
			dispatch('ui/loading', true, { root: true });

			const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

			const data = {
				nens_id: vinculoSelecionado.nens_id,
				curs_id: vinculoSelecionado.curs_id,
				habi_id: vinculoSelecionado.habi_id,
				turn_id: vinculoSelecionado.turn_id,
				viac_sequencia: vinculoSelecionado.viac_sequencia,
			}

			const response = await http.get('/v1/aluno/requerimentos/tipos', data);
			const secoes = response.data.secoes;

			commit('setSecoes', secoes);
		} catch (error) {
			console.log(error)
		} finally {
			dispatch('ui/loading', false, { root: true });
		}
	},

	carregaTipoDados: async ({commit, state, dispatch}) =>  {
		try {
			dispatch('ui/loading', true, { root: true });
			const response = await http.get(`/v1/aluno/requerimentos/tipos/${state.tipoSelecionado.reqt_id}`);

			commit('setTipoDados', response.data.tipo);
		} catch (error) {
			console.log(error)
		} finally {
			dispatch('ui/loading', false, { root: true });
		}
	},

	async criar({ commit, state, rootGetters }) {
		const form = new FormData();

		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

		form.append('nens_id', vinculoSelecionado.nens_id);
		form.append('curs_id', vinculoSelecionado.curs_id);
		form.append('habi_id', vinculoSelecionado.habi_id);
		form.append('turn_id', vinculoSelecionado.turn_id);
		form.append('viac_sequencia', vinculoSelecionado.viac_sequencia);
		form.append('reqt_id', state.tipoSelecionado.reqt_id);
		form.append('descricao', state.motivoSolicitacao);

		if (state.labelExtra != null && state.labelExtra != undefined) {
			form.append('label_extra', state.labelExtra);
			form.append('informacao_extra', state.informacaoExtra);
		}

		for (const index in state.files) {
			if (Object.hasOwnProperty.call(state.files, index)) {
				const file = state.files[index];
				form.append(`anexos[${index}]`, file);
			}
		}

		if (state.disciplinasNaoDisponiveisSelecionadas.length > 0) {
			for (let i = 0, l = state.disciplinasNaoDisponiveisSelecionadas.length; i < l; i++) {
				form.append('cocu_id[]', state.disciplinasNaoDisponiveisSelecionadas[i]);
			}
		}

		const { data } = await http.post(
			'/v1/aluno/requerimentos',
			form,
			{
				'Content-Type': 'multipart/form-data'
			}
		);

		commit('setMotivoSolicitacao', '');
		commit('setFiles', []);

		return data.requ_id;
	},

	async carregar({ commit, state, rootGetters }, filtro = '') {
		const now = (new Date()).getTime();

		const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

		const params = {};

		if (filtro) {
			params.filtro = filtro;
		}

		if (
			vinculoSelecionado !== 'Todos os Vínculos Ativos'
			&& vinculoSelecionado !== 'Todos os Vínculos'
		) {
			params.nens_id = vinculoSelecionado.nens_id;
			params.curs_id = vinculoSelecionado.curs_id;
			params.habi_id = vinculoSelecionado.habi_id;
			params.turn_id = vinculoSelecionado.turn_id;
			params.viac_sequencia = vinculoSelecionado.viac_sequencia;
		}

		const jsonFiltro = JSON.stringify(params);

		if (
			state.ultimaCarga
			&& state.ultimoFiltro == jsonFiltro
			&& (now - state.ultimaCarga) < 20000
		) {
			return;
		}

		const { data } = await http.get('/v1/aluno/requerimentos', params);

		commit('setUltimaCarga', now);
		commit('setUltimoFiltro', jsonFiltro);
		commit('setListagem', data.requerimentos.map(parseRequerimento));
	},

	async carregaSolicitacaoDetalhe({ commit }, id) {
		const { data } = await http.get('/v1/aluno/requerimentos/' + id);

		commit('setDetalhada', data.requerimento);
	},

	/* eslint-disable-next-line no-unused-vars */
	async obterArquivo(_, { requ_id, rean_id }) {
		const { data, headers } = await http.get(
			'/v1/aluno/requerimentos/' + requ_id + '/anexos/' + rean_id,
			{},
			{},
			'blob'
		);

		return {
			data,
			mime: headers['content-type'],
			extencao: headers['x-arquivo-extensao'],
		};
	},

	async carregaDisciplinasNaoDisponiveis({ commit, dispatch, rootGetters }) {
		try {
			commit('setLoading', true);

			dispatch('ui/loading', true, { root: true });

			const vinculoSelecionado = rootGetters['vinculosAcademicos/getVinculoSelecionado'];

			if (
				vinculoSelecionado === 'Todos os Vínculos Ativos'
				|| vinculoSelecionado === 'Todos os Vínculos'
			) {
				throw new Error('Escolha um vínculo primeiro');
			}

			const params = {
				nens_id: vinculoSelecionado.nens_id,
				curs_id: vinculoSelecionado.curs_id,
				habi_id: vinculoSelecionado.habi_id,
				turn_id: vinculoSelecionado.turn_id,
				viac_sequencia: vinculoSelecionado.viac_sequencia,
			};

			const { data } = await http.get(
				'/v1/aluno/requerimentos/disciplinas-nao-disponiveis',
				params
			);

			commit('setDisciplinasNaoDisponiveis', data.disciplinas);
		} finally {
			dispatch('ui/loading', false, { root: true });

			commit('setLoading', false);
		}
	},

	atualizaDisciplinasNaoDisponiveisSelecionadas({ commit }, selecionadas) {
		commit('setDisciplinasNaoDisponiveisSelecionadas', selecionadas);
	},

	limpaDisciplinasNaoDisponiveisSelecionadas({ commit }) {
		commit('setDisciplinasNaoDisponiveisSelecionadas', []);
	},
};

export default actions;
