import Vue from 'vue';
import Vuex from 'vuex';
import plugins from './plugins';
import modules from './modules';
import { getClientId, getAuthority, getRedirectUri, getEmailLoginDomain } from '@commons/office';

Vue.use(Vuex);

const store = new Vuex.Store({
	state: {
		ie: 'default',
		mailRecover: '',
		isLogged: false,
		alunoMatricula: null,
		alunoCPF: '',
		alunoCidade: '',
		alunoDataNascimento: '',
		alunoNome: '',
		aceiteTermoDocumentos: false,
		isGrad: false,
		isPos: false,
		fatura: {},
		calouro: false,
		alertNotifications: [],
		popupNotifications: [],
		periodo: null,
		cursosNaoAceitos: '',
		msalConfig: {
			auth: {
				clientId: getClientId(),
				authority: getAuthority(),
				redirectUri: getRedirectUri()
			},
			cache: {
				cacheLocation: 'localStorage'
			}
		},
		accessToken: '',
		emailLoginDomain: getEmailLoginDomain()
	},
	plugins,
	mutations: {
		setCursosNaoAceitos(state, value) {
			state.cursosNaoAceitos = value;
		},
		setIE(state, value) {
			state.ie = value;
		},
		setMailRecover(state, value) {
			state.mailRecover = value;
		},
		setAlunoMatricula(state, matricula) {
			state.alunoMatricula = matricula;
		},
		setAlunoCidade(state, cidade) {
			state.alunoCidade = cidade;
		},
		setAlunoNome(state, nome) {
			state.alunoNome = nome;
		},
		setAlunoCPF(state, cpf) {
			state.alunoCPF = cpf;
		},
		setAlunoDataNascimento(state, dataNascimento) {
			state.alunoDataNascimento = dataNascimento;
		},
		setAceiteTermoDocumentos(state, aceite) {
			state.aceiteTermoDocumentos = aceite;
		},
		setNivelDeEnsino(state, nivelEnsino) {
			switch (parseInt(nivelEnsino)) {
				case 1:
					state.isGrad = true;
					break;

				case 2:
					state.isPos = true;
					break;

				default:
					state.isGrad = false;
					state.isPos = false;
					break;
			}
		},
		setFatura(state, fatura) {
			state.fatura = fatura;
		},
		setCalouro(state, calouro) {
			state.calouro = calouro;
		},
		setAlertNotifications(state, alertNotifications) {
			state.alertNotifications = alertNotifications;
		},
		setPopupNotifications(state, popupNotifications) {
			state.popupNotifications = popupNotifications;
		},
		setPeriodo(state, periodo) {
			state.periodo = periodo;
		},
	},
	modules
});

export default store;
