import http from '@commons/http';
import { isNil, isObjectLike } from 'lodash';

const actions = {
	todosDocumentos: async ({ commit, rootGetters }) => {
		const vinculo = rootGetters['vinculosAcademicos/getVinculoSelecionado'];
		let filtro = null;

		if (!isNil(vinculo) && isObjectLike(vinculo)) {
			filtro = {
				nens_id: vinculo.nens_id,
				curs_id: vinculo.curs_id,
				habi_id: vinculo.habi_id,
				turn_id: vinculo.turn_id,
				viac_sequencia: vinculo.viac_sequencia,
				peri_id: vinculo.peri_id
			};
		}

		if (vinculo == 'Todos os Vínculos Ativos') {
			filtro = {
				stvi_ativo_inativo: 2
			};
		}

		try {
			commit('loading', true);

			const response = await http.get('/v1/aluno/lista-documentos', filtro);

			if (response.status === 200) {
				const data = response.data.message;

				const invalidados = [];
				const naoEnviados = [];
				const emAnalise = [];
				const pendentes = data.pendente || [];

				for (const doc of pendentes) {
					if (doc.docu_rejeitado === 't') {
						invalidados.push(doc);
						continue;
					}

					if (doc.docu_ordem == 1) {
						naoEnviados.push(doc);
						continue;
					}

					emAnalise.push(doc);
				}

				commit('setInvalidados', invalidados || []);
				commit('setNaoEnviados', naoEnviados || []);
				commit('setEmAnalise', emAnalise || []);
				commit('setValidados', data.entregue || []);
			}
		} catch (error) {
			console.log(error);
		} finally {
			commit('loading', false);
		}
	},

	enviarDocumento: async ({ commit }, document) => {
		const form = new FormData();
		form.append(document.docu_id, document.fileImage);

		if (document.type) {
			form.append('docu_tipo', document.type);
		}

		try {
			commit('loading', true);
			const response = await http.post('/v1/aluno/upload-documentos', form, {
				'Content-Type': 'multipart/form-data'
			});

			if (response.status === 200) {
				return true;
			}

			return false;
		} catch (error) {
			return false;
		} finally {
			commit('loading', false);
		}
	},

	enviarMergeDocumento: async ({ commit }, document) => {
		const form = new FormData();
		form.append(document.docu_id, document.fileImage);

		try {
			commit('loading', true);
			const response = await http.post(
				'/v1/aluno/upload-merge-documentos',
				form,
				{ 'Content-Type': 'multipart/form-data' }
			);

			if (response.status === 200) {
				return true;
			}

			return false;
		} catch (error) {
			return false;
		} finally {
			commit('loading', false);
		}
	},

	abrirDocumento: async ({ commit }, documento) => {
		if (documento.docu_link && documento.docu_link.trim().length > 0) {
			window.open(documento.docu_link, '_blank');
		} else {
			//Busca o base64
			try {
				commit('loading', true);
				const response = await http.get(
					`/v1/aluno/documento-local/${documento.alun_matricula}/${documento.docu_id}/${documento.aldo_versao}`
				);

				if (response.status === 200) {
					var documentoLink = response.data.message;
					if (documentoLink) {
						//Abre o base 64
						var a = document.createElement('a');
						a.href =
							'data:application/octet-stream;base64,' + documentoLink.base64;
						a.target = '_blank';
						if (documentoLink.file) {
							a.download = documentoLink.file;
						}
						a.click();
					}
				}
			} catch (error) {
				console.log(error);
				commit('loading', false);
				return false;
			} finally {
				commit('loading', false);
			}
		}
	},

	cancelarDocumento: async ({ commit, dispatch }, document) => {
		try {
			commit('loading', true);

			const response = await http.post(
				`/v1/aluno/cancelar-documentos?docu_id=${document.docu_id}`
			);

			if (response.status === 200) {
				await dispatch('todosDocumentos');
				return {
					success: true,
					message: null
				};
			}

			return {
				success: false,
				message: null
			};
		} catch (error) {
			return {
				success: false,
				message: error?.data?.message
			};
		} finally {
			commit('loading', false);
		}
	},

	loadEstruturaModalDocumentos: async ({ commit }, doc_id) => {
		let data = null;

		try {
			const response = await http.get(`/v1/aluno/detalhe-documento/${doc_id}`);

			data = response.data;
		} catch (e) {
			console.log(e);
		}

		const estrutura = {
			firstStep: {
				text: data?.documento?.docu_portal_inicio_descricao || '',
				types: data?.documento?.docu_tipo || null
			},
			secondStep: {
				head: data?.documento?.docu_portal_upload_cabecalho || '',
				imageURL: data?.documento?.docu_portal_upload_foto || '',
				imageDescription:
					data?.documento?.docu_portal_upload_foto_descricao || '',
				videoHTML: data?.documento?.docu_portal_upload_video_html || '',
				videoDescription:
					data?.documento?.docu_portal_upload_video_descricao || ''
			}
		};

		commit('setEstruturaModalEnvioDocumentos', estrutura);
	},

	docUploadFinished: async ({ commit }, document) => {
		try {
			commit('loading', true);
			await http.post(
				`/v1/aluno/upload-finalizado?docu_id=${document.docu_id}`
			);
		} finally {
			commit('loading', false);
		}
	}
};

export default actions;
