const getters = {
	getNovasExperiencias: (state) => {
		return state.novasExperiencias;
	},

	getMinhasExperiencias: (state) => {
		return state.minhasExperiencias;
	},

	getEncontrosComEspecialista: (state) => {
		return state.encontrosComEspecialista;
	},

	isLoading: (state) => {
		return state.isLoading;
	},

	contemDados: (state) => {
		if (state.novasExperiencias.length || state.minhasExperiencias.length || state.encontrosComEspecialista.length) {
			return true;
		}

		return false;
	},

	getInteresseAlto(state) {
		return state.interesseAlto;
	},

	getInteresseMuitoAlto(state) {
		return state.interesseMuitoAlto;
	},
	isModalVisualizado: (state) => {
		return state.visualizouModalExperiencias;
	},
	getCodigoIes: (state) => {
		return state.codigo_ies;
	}

};

export default getters;
